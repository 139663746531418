const jQuery = require("jquery");
window.jQuery = window.$ = jQuery;

const Cookies = require("js-cookie");
window.Cookies = Cookies;

// ####### BOOTSTRAP #########

const Alert = require("bootstrap/js/dist/alert");
window.Alert = Alert;

const Button = require("bootstrap/js/dist/button");
window.Button = Button;

const Collapse = require("bootstrap/js/dist/collapse");
window.Collapse = Collapse;

const Dropdown = require("bootstrap/js/dist/dropdown");
window.Dropdown = Dropdown;

const Modal = require("bootstrap/js/dist/modal");
window.Modal = Modal;

const Popover = require("bootstrap/js/dist/popover");
window.Popover = Popover;

const Lightbox = require("lightbox2/src/js/lightbox");
window.Lightbox = Lightbox;

// //import 'bootstrap/js/dist/carousel';
// //import 'bootstrap/js/dist/offcanvas';
// //import 'bootstrap/js/dist/scrollspy';
// //import 'bootstrap/js/dist/tab';
// //import 'bootstrap/js/dist/toast';
// //import 'bootstrap/js/dist/tooltip';

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

require("./content.js");
require("./job.js");
require("./cookie-consent.js");
require("./form.js");
require("./header.js");
require("./helpers.js");
require("./popup.js");
require("./privacy.js");
require("./recaptcha.js");

import "./../sass/app.sass";
