$(document).ready(() => {
    /**
     * Prüfe ob Formulare da sind?
     * Falls ja schaue nach ob Messages existieren die gezeigt werdeb müssen.
     * Falls ja, öffne das umliegende Collapse und scrolle zum Formular
     */
    var formMessages = $("form .message");
    var openAndScroll = false;
    if (formMessages.length > 0) {
        $.each(formMessages, function () {
            if ($.trim($(this).html()) !== "") {
                openAndScroll = true;
                return false;
            }
        });

        if (openAndScroll) {
            var parentCollapse = formMessages.first().closest(".collapse");

            if (parentCollapse.length > 0) {
                parentCollapse.collapse("show");
            }

            setTimeout(function () {
                $("html, body").animate(
                    {
                        scrollTop:
                            formMessages.first().closest("form").offset().top -
                            120,
                    },
                    250,
                    "swing"
                );
            }, 250);
        }
    }

    $("#howtoapply-button").click(function () {
        $("html, body").animate(
            {
                scrollTop: $("#howtoapply").offset().top - 150,
            },
            250,
            "swing"
        );
    });
});

function badgeCookie() {
    // Get all elements with class deadline-badge via vanilla js

    const deadlineBadges = document.querySelectorAll(".deadline-badge");
    // Get the element with class application-deadline via vanilla js
    const applicationDeadlines = document.querySelectorAll(
        ".application-deadline"
    );
    const h1 = document.querySelector("h1");

    if (deadlineBadges.length) {
        // Get the jobID from the data-jobid attribute
        const jobID = h1.getAttribute("data-jobid");
        let deadlineCookie = getCookie("deadline-" + jobID);
        let deadlineCookieExtended = getCookie("deadline-extended-" + jobID);
        let deadlineCookieExtendedLocal = false;

        let dateToday = new Date();

        if (deadlineCookie) {
            deadlineCookie = new Date(parseInt(deadlineCookie));

            // Check if the deadline is extended
            if (dateToday > deadlineCookie) {
                setCookie("deadline-extended-" + jobID, true, {
                    expires: 90,
                    secure: true,
                    sameSite: "strict",
                });
                deadlineCookieExtendedLocal = true;
                dateToday.setDate(dateToday.getDate() + 1);
                dateToday.setHours(23);
                dateToday.setMinutes(59);
                dateToday.setSeconds(59);
                deadlineCookie = dateToday.valueOf();
                setCookie("deadline-" + jobID, deadlineCookie.toString(), 90);
                deadlineCookie = new Date(deadlineCookie);
            }

            // Check if the deadline is extended
            if (deadlineCookieExtended || deadlineCookieExtendedLocal) {
                // remove class d-none from all deadlineBadges
                for (let i = 0; i < deadlineBadges.length; i++) {
                    deadlineBadges[i].classList.remove("d-none");
                }
            }

            // for each applicationDeadlines element

            for (let i = 0; i < applicationDeadlines.length; i++) {
                // Set the content of the element with class application-deadline to
                applicationDeadlines[i].innerHTML =
                    deadlineCookie.getDate().toString().padStart(2, "0") +
                    "." +
                    (1 + deadlineCookie.getMonth())
                        .toString()
                        .padStart(2, "0") +
                    "." +
                    deadlineCookie.getFullYear();
            }
        } else {
            // Get the content from the element application-deadline and convert it to an date object
            const actualDeadline = applicationDeadlines[0].innerHTML.split(".");
            const myDate = new Date(
                actualDeadline[2],
                actualDeadline[1] - 1,
                actualDeadline[0]
            );

            // Set the cookie with the deadline
            setCookie("deadline-" + jobID, myDate.getTime().toString(), 90);
        }
    }
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
